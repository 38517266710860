import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./assets/bootstrap.min.css";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import logo from "./assets/camphill-logo-237x102x0x0x237x102x1649837634.png";
import { Text } from "@fluentui/react";
import "./App.css";
initializeIcons();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="container">
      <nav className="navbar navbar-light bg-white">
        <a className="navbar-brand d-flex align-items-end" href="#">
          <img src={logo} height="102" alt="" />
          <Text>
            <h3 style={{ fontWeight: 600 }} className="ml-2 color-primary">
              Heftbestellung
            </h3>
          </Text>
        </a>
      </nav>
      <App />
      <footer className="text-center pt-4 my-md-5 pt-md-5 border-top">
        <div className="row">
          <div className="col-12 col-md">
            <img className="mb-2" src={logo} alt="" height="64" />
            <small className="d-block mb-3 text-muted">&copy; Camphill Liebenfels {new Date().getFullYear()}</small>
            <small className="d-block mb-3 text-muted">Created by brandreact</small>
          </div>
        </div>
      </footer>
    </div>
  </React.StrictMode>
);
